import { graphql } from "gatsby"
import React from "react"
import "twin.macro"

import { Article, BackgroundImage, Container, Headings, Section } from "@/atoms"
import {
  BreadCrumbs, CategoryLinks, Layout, NextSEO, Pagination, PostListing, SEO
} from "@/components"
import { CategoryPageQuery, SitePageContext } from "@/gatsby-graphql"
import tw from "twin.macro"

export type CategoryPageProps = {
  data: CategoryPageQuery
  pageContext: SitePageContext
  location: any
}

export default function CategoryTemplate({
  pageContext,
  data,
  location,
}: CategoryPageProps) {
  const { currentPageNum, pageCount } = pageContext
  const postEdges = data.allWpPost.edges
  const displayName = data.wpCategory.name
  return (
    <Layout>
      {/* <SEO
        title={
          currentPageNum === 1
            ? `${displayName}の記事一覧`
            : `${displayName}の記事一覧-${currentPageNum}ページ目`
        }
        description={
          currentPageNum === 1
            ? `${displayName}の記事一覧`
            : `${displayName}の記事一覧-${currentPageNum}ページ目`
        }
      /> */}
      <NextSEO
        type="Article"
        title={currentPageNum === 1 ? `${displayName}の記事一覧` : `${displayName}の記事一覧-${currentPageNum}ページ目`}
        description={currentPageNum === 1 ? `${displayName}の記事一覧` : `${displayName}の記事一覧-${currentPageNum}ページ目`}
        url={`/blog`}
        breadCrumbs={[
          { title: `ブログ`, uri: `/blog` },
          {
            title: currentPageNum === 1 ? `${displayName}の記事一覧` : `${displayName}の記事一覧-${currentPageNum}ページ目`,
            uri: `/blog/${displayName}`,
          },
        ]}
      />
      <BackgroundImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        header={<Headings variant="h2" as='h1'>{displayName}の記事一覧 </Headings>}
      />
      <Article maxWidth={'tablet'} css={{
        ...tw`space-y-12 pb-12 px-4`,
        '@tablet': { ...tw`px-0` }
      }}>
        <BreadCrumbs
          breadCrumbs={[
            { title: `ブログ`, uri: `/blog` },
            {
              title:
                currentPageNum === 1
                  ? `${displayName}の記事一覧`
                  : `${displayName}の記事一覧-${currentPageNum}ページ目`,
              uri: `/blog/${currentPageNum}`,
            },
          ]}
        />
        <Section css={{ ...tw`` }}>
          <PostListing
            postEdges={postEdges}
            isCategoryListing
            css={{
              ...tw`border p-4 rounded`,
              "&:hover": {
                backgroundColor: '$background-secondary'
              }
            }} />
        </Section>
        {pageCount > 1 && <Pagination pageContext={pageContext} />}
        <aside>
          <CategoryLinks activeName={displayName} />
        </aside>
      </Article>
    </Layout>
  )
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryPage($categoryId: Int!, $skip: Int!, $limit: Int!) {
    allWpPost(
      sort: { order: DESC, fields: date }
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryId } } }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          databaseId
          date
          uri
          modified
          slug
          id
          title
          content
          categories {
            nodes {
              databaseId
              name
              slug
              uri
            }
          }
        }
      }
    }
    wpCategory(databaseId: { eq: $categoryId }) {
      name
    }
    allWpCategory(filter: { count: { gt: 0 } }) {
      nodes {
        databaseId
        uri
        count
        name
        slug
      }
    }
    placeholderImage: file(relativePath: { eq: "image4.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [WEBP]
        )
      }
    }
  }
`
